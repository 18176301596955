<template>

    <div class="inner-container" :class="{ 'min-container': !getTabs }">
      <div class="page-header">
      <h1>{{ $t("ESTIMATE") }}</h1>
      <b-button
        size="sm"
        variant="success"
        class="button-succes-style ml-2 float-right mb-2"
        @click="handleStore"
        ><font-awesome-icon icon="plus-circle" /> {{ $t("NEW") }}</b-button
      >
    </div>
    <!-- <div class="d-flex justify-content-between mt-5" >
    
      <h1 class="card rounded ml-3 w-100 title mt-2 pt-2 pb-2 px-2">{{ $t("ESTIMATE") }}</h1>

      <div class="dropdown ">
        <b-button variant="success" class="dropdown-btn py-2 d-flex align-items-center mt-2 ">
          <font-awesome-icon icon="plus-circle" class="me-2" /> 
          {{ $t("NEW") }}
        </b-button>
        <div class="dropdown-content">
          <a @click="goToRoute('/devis/devisTypesBAR-TH')"> BAR-TH</a>
             <a @click="goToRoute('/soon')"> BAR-TH</a> 
          
          <a @click="goToRoute('/devis/devisTypesBAR-EN')">BAR-EN</a>
          <a @click="goToRoute('/devis/manuel')">Libre</a>
        </div>
     
    </div>
  </div> -->
    <div class="content mt-0">
      <div class="content-header">
        <searchBar
          :searchText="searchBarText"
          :searchFunc="devis"
          :filtre="{
            start_date: start,
            end_date: end,
            entreprise: current_entreprise,
            brouillon: brouillon,
          }"
          :showFiltre="true"
          @returnValue="updateSearch($event)"
        ></searchBar>
        <!--------------------------------------- entrepriseModal --------------------------------------->
        <b-modal id="entrepriseModal" ref="entrepriseModal">
          <template #modal-header="{ close }">
            <h5>{{ $t("COMPANY_ID") }}</h5>
            <b-button size="sm" @click="close()">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17.028"
                height="17.028"
                viewBox="0 0 17.028 17.028"
              >
                <path
                  id="Icon_material-close"
                  data-name="Icon material-close"
                  d="M24.528,9.215,22.813,7.5l-6.8,6.8-6.8-6.8L7.5,9.215l6.8,6.8-6.8,6.8,1.715,1.715,6.8-6.8,6.8,6.8,1.715-1.715-6.8-6.8Z"
                  transform="translate(-7.5 -7.5)"
                  fill="#393939"
                />
              </svg>
            </b-button>
          </template>

          <form @submit.prevent="hideModal('entrepriseModal')">
            <div class="center">
              <b-form-group :label="$t('COMPANY_ID')" v-if="this.isSuperAdmin">
                <searchInput
                  :list="getAllentreprises"
                  :loader="getentrepriseLoading"
                  label="lib"
                  :searchFunc="all_entreprises"
                  @searchfilter="selectentreprise($event)"
                ></searchInput>
              </b-form-group>

              <!---filt-->

              <b-form-group :label="$t('CUSTOMER')">
                <searchInput
                  :list="getAllclients"
                  :loader="getclientLoading"
                  label="nom"
                  :selected="filtre_client"
                  :limit="2"
                  :searchFunc="all_clients"
                  @searchfilter="selectClient($event)"
                  :placeholder="$t('CUSTOMER')"
                ></searchInput>
              </b-form-group>
              <b-form-group :label="$t('DEVIS')">
                <searchInput
                  :list="getAllTypedevis"
                  label="label"
                  :selected="filtre_devis"
                  :limit="2"
                  @searchfilter="selectDevis($event)"
                  :placeholder="$t('DEVIS')"
                ></searchInput>
              </b-form-group>
              <b-form-checkbox
                v-model="filtre_brouillon"
                name="brouillon"
                :value="1"
                :unchecked-value="0"
              >
                {{ $t("DRAFT") }}
              </b-form-checkbox>
            </div>
          </form>
          <template #modal-footer>
            <div class="actionModel">
              <b-button variant="success" @click="setup(true)">
                <div class="block-spinner">
                  {{ $t("Appliquer") }}
                </div>
              </b-button>
            </div>
          </template>
        </b-modal>
        <!--------------------------------------- end entrepriseModal --------------------------------------->
        <!--------------------------------------- Filtre --------------------------------------->
        <div class="filtre" v-b-modal.Filtre>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 24 21.688"
            class="mr-2"
          >
            <g
              id="Icon_feather-calendar"
              data-name="Icon feather-calendar"
              transform="translate(0.75 0.75)"
            >
              <path
                id="Tracé_78085"
                data-name="Tracé 78085"
                d="M6.519,6H20.65a2.019,2.019,0,0,1,2.019,2.019V22.15a2.019,2.019,0,0,1-2.019,2.019H6.519A2.019,2.019,0,0,1,4.5,22.15V8.019A2.019,2.019,0,0,1,6.519,6Z"
                transform="translate(-4.5 -3.981)"
                fill="none"
                stroke="#fff"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
              />
              <path
                id="Tracé_78086"
                data-name="Tracé 78086"
                d="M24,3V7.037"
                transform="translate(-10.878 -3)"
                fill="none"
                stroke="#fff"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
              />
              <path
                id="Tracé_78087"
                data-name="Tracé 78087"
                d="M12,3V7.037"
                transform="translate(-6.953 -3)"
                fill="none"
                stroke="#fff"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
              />
              <path
                id="Tracé_78088"
                data-name="Tracé 78088"
                d="M4.5,15H22.669"
                transform="translate(-4.5 -6.925)"
                fill="none"
                stroke="#fff"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
              />
            </g>
          </svg>
          <span v-if="start">{{ moment(start).format("DD,MMM") }} &nbsp;-</span>
          <span v-if="!start && end">x &nbsp;-</span>
          <span v-if="!start && !end">{{ $t("FILTER_PER_DATE") }}</span>
          <span v-if="end">&nbsp; {{ moment(end).format("DD,MMM") }}</span>
          <span v-if="start && !end">&nbsp; x</span>
        </div>
        <!-- ----------------------------------------------------------------------Filtre----------------------------------------------------- -->
      </div>
      <!-- ------------------------------------------------------------------------------------Filtre------------------------------------------------------------------- -->
      <b-modal id="Filtre" ref="Filtre" scrollable>
        <template #modal-header="{ close }">
          <h5>{{ $t("FILTER_PER_DATE") }}</h5>
          <b-button size="sm" @click="close()">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17.028"
              height="17.028"
              viewBox="0 0 17.028 17.028"
            >
              <path
                id="Icon_material-close"
                data-name="Icon material-close"
                d="M24.528,9.215,22.813,7.5l-6.8,6.8-6.8-6.8L7.5,9.215l6.8,6.8-6.8,6.8,1.715,1.715,6.8-6.8,6.8,6.8,1.715-1.715-6.8-6.8Z"
                transform="translate(-7.5 -7.5)"
                fill="#393939"
              />
            </svg>
          </b-button>
        </template>
        <b-row>
          <b-col md="12" class="mb-4">
            <b-calendar
              v-model="start"
              @context="onContext"
              locale="fr-FR"
              block
            ></b-calendar>
          </b-col>

          <b-col md="12">
            <b-calendar
              v-model="end"
              @context="onContext"
              locale="fr-FR"
              block
            ></b-calendar>
          </b-col>
        </b-row>

        <template #modal-footer>
          <div class="double">
            <b-button variant="danger" @click="resetDate()">
              <div class="block-spinner">{{ $t("CANCEL") }}</div>
            </b-button>

            <b-button variant="success" @click="closeFilter()">
              <div class="block-spinner">
                {{ $t("CONFIRM") }}
              </div>
            </b-button>
          </div>
        </template>
      </b-modal>
      <!--------------------------------------- Filtre --------------------------------------->
      <div class="filtre">
        <p class="table-count">{{ getTotalRowdevis }} {{ $t("ESTIMATE") }}</p>
        <div class="productTrie" v-if="current_entreprise">
          {{ current_entreprise.lib }}
          <font-awesome-icon
            icon="times-circle"
            class="icons"
            @click="
              filtre_entreprise = null;
              setup(true);
            "
          />
        </div>
        <div class="productTrie" v-if="currentclient">
          {{ currentclient.nom }}
          <font-awesome-icon
            icon="times-circle"
            class="icons"
            @click="
              filtre_client = null;
              setup(true);
            "
          />
        </div>
        <!---->

        <div class="productTrie" v-if="current_devis_type">
          {{ current_devis_type.label }}
          <font-awesome-icon
            icon="times-circle"
            class="icons"
            @click="
              filtre_devis = null;
              setup(true);
            "
          />
        </div>
        <div class="productTrie" v-if="current_brouillon">
          {{ $t("DRAFT") }}
          <font-awesome-icon
            icon="times-circle"
            class="icons"
            @click="
              filtre_brouillon = null;
              setup(true);
            "
          />
        </div>
      </div>
      <div class="mx-2">
        <b-alert v-model="showAlert" class="mr-12" dismissible variant="danger">
          On ne peut pas télécharger le brouillon.
        </b-alert>
      </div>

      <div class="table-rapport-style w-100">
        <table>
          <thead>
            <tr>
              <th style="border-radius: 5px 0px 0px 0px">
                {{ $t("ESTIMATE") }} N°
              </th>
              <th>
                {{ $t("CUSTOMER") }}
              </th>
              <th v-if="isSuperAdmin">
                {{ $t("ENTREPRISES") }}
              </th>
              <th>
                {{ $t("TYPE") }}
              </th>
              <th>
                {{ $t("DATE") }}
              </th>
              <th>
                {{ $t("SIGNA") }}
                <span
                  @click="sortBy('brouillon', 1)"
                  :class="{ 'active-sort': current_brouillon === 1 }"
                  class="sort-arrow"
                  >↑</span
                >
                <span
                  @click="sortBy('brouillon', 0)"
                  :class="{ 'active-sort': current_brouillon === 0 }"
                  class="sort-arrow"
                  >↓</span
                >
              </th>
              <th>{{ $t("Total TTC") }}</th>
              <th>
                {{ $t("DOWNLOAD") }}
              </th>
              <th style="border-radius: 0px 5px 0px 0px">
                {{ $t("EMAIL") }}
              </th>
              <th class="action_head"></th>
            </tr>
          </thead>
          <tbody>
            <tr
              role="row"
              class="ligneNormale"
              v-for="(data, i) in getAlldevis"
              :key="i"
            >
              <td
                aria-colindex="1"
                role="cell"
                @click.prevent.stop="handleDevis(data)"
              >
    
                {{ data.devis.numerodevis }}
              </td>
              <td
                aria-colindex="2"
                role="cell"
                @click.prevent.stop="handleDevis(data)"
                v-if="data.devis.client"
              >
              
                {{ data.devis.client.nom }} {{ data.devis.client.prenom }}
              </td>
              <td
                aria-colindex="2"
                role="cell"
                @click.prevent.stop="handleDevis(data)"
                v-else
              >
                -
              </td>
              <td
                aria-colindex="3"
                role="cell"
                @click.prevent.stop="handleDevis(data)"
                v-if="data.entreprise && isSuperAdmin"
              >
             
                {{ data.entreprise.lib }}
              </td>
              <td
                aria-colindex="4"
                role="cell"
                @click.prevent.stop="handleDevis(data)"
              >
                {{ data.devis.type_devis }}
              </td>
              <td
                aria-colindex="4"
                role="cell"
                @click.prevent.stop="handleDevis(data)"
              >
                {{ moment(data.devis.debut_devis).format("DD-MM-YYYY") }}
              </td>
              <td
                aria-colindex="4"
                role="cell"
                @click.prevent.stop="handleDevis(data)"
              >
                <span v-if="data.is_signed == 'pending'" class="encours">
                  {{ $t("PENDING") }}
                </span>
                <span v-else-if="data.is_signed == 'signed'" class="end">
                  {{ $t("SIGNED") }}
                </span>
                <span
                  v-else-if="data.brouillon"
                  class="end"
                  style="background: #e4261b"
                >
                  {{ $t("DRAFT") }}
                </span>
                <span v-else class="created">
                  {{ $t("unsigned") }}
                </span>
              </td>
              <td
                aria-colindex="5"
                role="cell"
                @click.prevent.stop="handleDevis(data)"
                v-if="data.total_to_pay"
              >
                {{ data.total_to_pay | financial }}
              </td>
              <td
                aria-colindex="5"
                role="cell"
                @click.prevent.stop="handleDevis(data)"
                v-else
              >
                {{ data.total_TTC | financial }}
              </td>
              <td aria-colindex="7" role="cell">
                <div class="grey" @click="handleClick(data)">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 20.944 20.944"
                  >
                    <g
                      id="Icon_feather-download"
                      data-name="Icon feather-download"
                      transform="translate(-3.5 -3.5)"
                    >
                      <path
                        id="Tracé_78100"
                        data-name="Tracé 78100"
                        d="M23.444,22.5v4.21a2.1,2.1,0,0,1-2.1,2.1H6.6a2.1,2.1,0,0,1-2.1-2.1V22.5"
                        transform="translate(0 -5.37)"
                        fill="none"
                        stroke="#28367a"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                      />
                      <path
                        id="Tracé_78101"
                        data-name="Tracé 78101"
                        d="M10.5,15l5.262,5.262L21.025,15"
                        transform="translate(-1.79 -3.133)"
                        fill="none"
                        stroke="#28367a"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                      />
                      <path
                        id="Tracé_78102"
                        data-name="Tracé 78102"
                        d="M18,17.13V4.5"
                        transform="translate(-4.028)"
                        fill="none"
                        stroke="#28367a"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                      />
                    </g>
                  </svg>
                </div>
              </td>

              <!-- <td aria-colindex="7" role="cell">
                <div class="grey" @click="generate(data)">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 20.944 20.944"
                  >
                    <g
                      id="Icon_feather-download"
                      data-name="Icon feather-download"
                      transform="translate(-3.5 -3.5)"
                    >
                      <path
                        id="Tracé_78100"
                        data-name="Tracé 78100"
                        d="M23.444,22.5v4.21a2.1,2.1,0,0,1-2.1,2.1H6.6a2.1,2.1,0,0,1-2.1-2.1V22.5"
                        transform="translate(0 -5.37)"
                        fill="none"
                        stroke="#28367a"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                      />
                      <path
                        id="Tracé_78101"
                        data-name="Tracé 78101"
                        d="M10.5,15l5.262,5.262L21.025,15"
                        transform="translate(-1.79 -3.133)"
                        fill="none"
                        stroke="#28367a"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                      />
                      <path
                        id="Tracé_78102"
                        data-name="Tracé 78102"
                        d="M18,17.13V4.5"
                        transform="translate(-4.028)"
                        fill="none"
                        stroke="#28367a"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                      />
                    </g>
                  </svg>
                </div>
              </td> -->
              <td
                aria-colindex="6"
                role="cell"
                @click.prevent.stop="handleDevis(data)"
                v-if="data.devis.client"
              >
                {{ data.devis.client.email }}
              </td>
              <td
                aria-colindex="6"
                role="cell"
                @click.prevent.stop="handleDevis(data)"
                v-else
              >
                -
              </td>

              <td
                aria-colindex="7"
                role="cell"
                class="action_col"
                v-if="devisDetail"
              >
                <div
                  class="btn btn-sm"
                  v-if="
                    isSuperAdmin ||
                    isentrepriseAdmin ||
                    isAssistant ||
                    isCommercial ||
                    getOnlineUser.id == devisDetail.user.id
                  "
                  @click.prevent.stop="handleDelete(data)"
                >
                  <font-awesome-icon icon="trash" />
                </div>

                <div
                  class="btn btn-sm"
                  v-if="
                    isSuperAdmin ||
                    isentrepriseAdmin ||
                    isAssistant ||
                    isCommercial
                  "
                  @click.prevent.stop="handleCopy(data)"
                >
                  <font-awesome-icon icon="copy" />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!--------------------------------------- DETAILS_DEVIS --------------------------------------->
      <b-modal
        id="Modeldevis"
        ref="Modeldevis"
        scrollable
        size="xl"
        class="detaildevisModale"
      >
        <template #modal-header="{ close }">
          <h5>{{ $t("DETAILS_DEVIS") }} {{ devisDetail.devis.numerodevis }}</h5>
          <b-button size="sm" @click="close()">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17.028"
              height="17.028"
              viewBox="0 0 17.028 17.028"
            >
              <path
                id="Icon_material-close"
                data-name="Icon material-close"
                d="M24.528,9.215,22.813,7.5l-6.8,6.8-6.8-6.8L7.5,9.215l6.8,6.8-6.8,6.8,1.715,1.715,6.8-6.8,6.8,6.8,1.715-1.715-6.8-6.8Z"
                transform="translate(-7.5 -7.5)"
                fill="#393939"
              />
            </svg>
          </b-button>
        </template>

        <div v-if="devisDetail" class="modal-test">
          <div>
            <div class="cours">
              <p
                v-if="devisDetail.is_signed == 'pending'"
                style="background: #ffb300"
              >
                {{ $t("PENDING") }}
              </p>
              <p
                v-else-if="devisDetail.is_signed == 'signed'"
                style="background: #34c38f"
              >
                {{ $t("SIGNED") }}
              </p>
              <p v-else-if="devisDetail.brouillon" style="background: #e4261b">
                {{ $t("DRAFT") }}
              </p>
              <p v-else>
                {{ $t("CREATED") }}
              </p>
              <!----->
              <div v-if="devisDetail">
                <b-button
                  variant="success"
                  @click="handleUpdate()"
                  v-if="
                    (isSuperAdmin ||
                      isentrepriseAdmin ||
                      isAssistant ||
                      isCommercial ||
                      getOnlineUser.id == devisDetail.user.id) &&
                    devisDetail.is_signed != 'signed' &&
                    !devisDetail.devis.facture
                  "
                >
                  {{ $t("EDIT") }}
                  <div v-if="editLoader" class="loading ml-2">
                    <div class="spinner-border" role="status"></div>
                  </div>
                </b-button>
              </div>
            </div>
          </div>
          <div class="part justify">
            <div class="divborder">
              <p>{{ $t("COORDONNEES_DEVIS") }}</p>
            </div>

            <div class="left">
              <ul>
                <h2 class="textstyle">{{ devisDetail.entreprise.lib }}</h2>
                <span class="adressinfo">
                  <span class="info textstyle">
                    {{ devisDetail.entreprise.rue }}
                  </span>
                  <span class="info textstyle">
                    {{ devisDetail.entreprise.cp }}
                    {{ devisDetail.entreprise.ville }}
                  </span>
                </span>

                <li>
                  <span class="entrepriseinfo">{{ $t("SIRET") }}</span>
                  <span class="textstyle">{{
                    devisDetail.entreprise.siret
                  }}</span>
                </li>
                <li>
                  <span class="entrepriseinfo">{{ $t("NUM_TVA") }}</span>
                  <span class="textstyle">{{
                    devisDetail.entreprise.num_tva
                  }}</span>
                </li>
                <li>
                  <span class="entrepriseinfo">{{ $t("TEL") }}</span>
                  <span>{{
                    devisDetail.entreprise.phone
                      ? devisDetail.entreprise.phone.formated_phone
                      : ""
                  }}</span>
                </li>

                <div class="block">
                  <li v-if="devisDetail.devis.interlocuteur">
                    <span class="title">{{ $t("INTERLOCUTEUR") }}:</span>
                    <span class="textstyle">
                      {{ devisDetail.devis.interlocuteur.nom }}
                      {{ devisDetail.devis.interlocuteur.prenom }}</span
                    >
                  </li>
                  <li v-if="devisDetail.devis.visite_technique">
                    <span class="title"
                      >{{ $t("DATE_VISITE_TECHNIQUE") }}:</span
                    >
                    <span>{{
                      moment(devisDetail.devis.visite_technique).format(
                        "DD-MM-YYYY"
                      )
                    }}</span>
                  </li>
                  <li>
                    <span class="title">{{ $t("DATE_DEVIS") }}:</span>
                    <span>{{
                      moment(devisDetail.devis.debut_devis).format("DD-MM-YYYY")
                    }}</span>
                  </li>
                  <li v-if="devisDetail.devis.fin_devis">
                    <span class="title">{{ $t("DATE-FIN") }}:</span>
                    <span>{{
                      moment(devisDetail.devis.fin_devis).format("DD-MM-YYYY")
                    }}</span>
                  </li>
                  <li v-if="devisDetail.devis.prime">
                    <span class="title">{{ $t("TYPE_DEVIS") }}:</span>
                    <span>{{ $t(devisDetail.devis.prime) }} </span>
                  </li>
                </div>

                <!-- <li>
                  <span class="title">{{ $t("DATE-FIN") }}:</span>
                  <span>{{
                    moment(devisDetail.devis.fin_devis).format("DD-MM-YYYY")
                  }}</span>
                </li> -->
              </ul>
            </div>

            <div class="right">
              <h1>{{ $t("ESTIMATE") }}</h1>
              <span class="info">N°{{ devisDetail.devis.numerodevis }}</span>

              <h2 class="textstyle" v-if="devisDetail.devis.client">
                {{ devisDetail.devis.client.prenom }} {{ devisDetail.devis.client.nom }}
              </h2>

              <span class="adressinfo textstyle" v-if="devisDetail.devis.client">
                <span>
                  <span class="info textstyle">{{
                    devisDetail.devis.client.rue
                  }}</span>
                  <span class="info textstyle">
                    {{ devisDetail.devis.client.cp }}
                    {{ devisDetail.devis.client.ville }}
                  </span>
                </span>

                <span>
                  <span class="entrepriseinfo">{{ $t("TEL") }} </span>
                  <span class="info">
                    {{
                      devisDetail.devis.client.phone
                        ? devisDetail.devis.client.phone.formated_phone
                        : ""
                    }}</span
                  >
                </span>

                <h3 v-if="devisDetail.devis.delegataire">
                  <span class="part-title"> {{ $t("DELEGATAIRE") }}:</span>
                  <span>{{ devisDetail.devis.delegataire.nom }}</span>
                </h3>
              </span>
            </div>
          </div>
          <h3
            class="description-container"
            v-if="devisDetail.devis.description"
          >
            <span class="title-description mr-1"> {{ $t("DESC") }}: </span>
            <span>{{
              reddescription
                ? devisDetail.devis.description
                : devisDetail.devis.description.slice(0, 300)
            }}</span>
            <span
              class="read"
              v-if="devisDetail.devis.description.length > 300"
              @click="reddescription = !reddescription"
            >
              ...{{ reddescription ? $t("LESS") : $t("MORE") }}
            </span>
          </h3>
          <div class="divborder">
            <p>{{ $t("DETAILS_DEVIS") }}</p>
          </div>

          <div
            v-for="(groupe, x) in devisDetail.groupeLigneDocument"
            :key="x"
            class="group"
          >
            <div class="piece">
              <div>
                <p>{{ $t("PIECE_") }}:</p>
                <p class="textstyle" style="font-weight: 600">
                  {{ groupe.piece }}
                </p>
              </div>
              <div class="icone-down-up">
                <p v-if="!showGroup[x].bool">{{ $t("TOTAL_PIECE_TTC") }}</p>
                <span v-if="!showGroup[x].bool">
                  {{ groupe.total_TTC | financial }}
                </span>

                <b-button size="sm" @click="closeGroup(x)">
                  <font-awesome-icon
                    icon="chevron-down"
                    class="icons"
                    v-if="!showGroup[x].bool"
                  />
                  <font-awesome-icon icon="chevron-up" class="icons" v-else />
                </b-button>
              </div>
            </div>
            <div class="part" v-show="showGroup[x].bool">
              <ul>
                <li class="traveauxtitle">
                  <span class="title" style="font-weight: 600,font-size: 14px;"
                    >{{ $t("WORK") }} :
                  </span>
                  <span class="textstyle"> {{ groupe.traveaux }}</span>
                </li>
              </ul>
              <table class="table">
                <thead>
                  <tr>
                    <th style="border-radius: 5px 0px 0px 0px" scope="col">
                      {{ $t("REF") }}
                    </th>
                    <th>{{ $t("WORDING") }}/{{ $t("DESC") }}</th>
                    <th>{{ $t("QTE") }}</th>
                    <!-- <th>{{ $t("UNITEU") }}</th> -->
                    <th>{{ $t("PUHT") }}</th>
                    <th>
                      {{ $t("TOTALHT") }}
                    </th>
                    <th v-if="devisDetail.remise_mode != 'global'">
                      {{ $t("REMISE") }}
                    </th>
                    <th v-if="devisDetail.remise_mode != 'global'">
                      {{ $t("Avec Remise") }}
                    </th>
                    <th style="border-radius: 0px 5px 0px 0px">
                      {{ $t("VAT") }}
                    </th>
                  </tr>
                </thead>
                <tbody
                  v-for="(
                    product, y
                  ) in devisDetail.GroupeLigneDocument_produit"
                  :key="y"
                >
                  <tr
                    v-if="
                      groupe.id == product.groupelignedocument_id &&
                      readActivated[y]
                    "
                  >
                    <td>{{ product.ref ? product.ref : "-" }}</td>
                    <td class="blocklibdesc">
                      <div class="libblock">
                        {{ product.lib ? product.lib : "" }}
                      </div>
                      <div
                        class="descblock"
                        v-if="product.desc"
                        :class="{ 'auto-heigth': readActivated[y].bool }"
                      >
                        {{
                          readActivated[y].bool
                            ? product.desc
                            : product.desc.slice(0, 105)
                        }}
                        <span
                          class="read"
                          v-if="product.desc.length > 105"
                          @click="
                            readActivated[y].bool = !readActivated[y].bool
                          "
                        >
                          ...{{
                            readActivated[y].bool ? $t("LESS") : $t("MORE")
                          }}
                        </span>
                      </div>
                    </td>
                    <td>{{ product.quantite | financial(true) }}</td>
                    <!-- <td>{{ product.type_unite }}</td> -->

                    <td>{{ product.unit_price | financial }}</td>
                    <td>
                      {{ (product.unit_price * product.quantite) | financial }}
                    </td>
                    <td v-if="devisDetail.remise_mode != 'global'">
                      {{ product.remise_value ? product.remise_value : 0 }}
                      {{ product.remise_type == "remise.HT" ? "€" : "%" }}
                    </td>
                    <td v-if="devisDetail.remise_mode != 'global'">
                      {{ totalAR(product) | financial }}
                    </td>
                    <td>{{ product.tva }} %</td>
                  </tr>
                </tbody>
              </table>
              <div class="flexEnd">
                <div class="sousTraitant">
                  <p v-if="groupe.sous_traitant">
                    Sous-traitant: {{ groupe.sous_traitant.name }}, SIRET:
                    {{ groupe.sous_traitant.num_siret }}, Num RGE:
                    {{ groupe.sous_traitant.num_rge }}
                  </p>
                </div>
                <div class="subtable">
                  <table class="complet">
                    <tr>
                      <td class="thead">
                        {{ $t("TOTAL_HT") }}
                      </td>
                      <td>{{ groupe.total_HT | financial }}</td>
                    </tr>
                    <tr v-if="groupe.remise">
                      <td class="thead">
                        {{ $t("REMISE") }}
                      </td>
                      <td>{{ groupe.remise | financial }}</td>
                    </tr>
                    <tr v-if="groupe.remise">
                      <td class="thead">
                        {{ $t("TOTALREMISE") }}
                      </td>
                      <td>{{ groupe.total_HT_with_remise | financial }}</td>
                    </tr>
                    <template v-if="groupe.tva_groupes.length">
                      <tr
                        v-for="tva in groupe.tva_groupes"
                        :key="'tva' + tva.id"
                      >
                        <td class="thead">TVA ({{ tva.tva }}%)</td>
                        <td style="border-radius: 0px 5px 0px 0px">
                          {{ tva.tva_montant | financial }}
                        </td>
                      </tr>
                    </template>
                    <tr>
                      <td class="thead">
                        {{ $t("TOTAL_PIECE_TTC") }}
                      </td>
                      <td>{{ groupe.total_TTC | financial }}</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="flexEndTotale">
            <div class="subtableTotale">
              <table class="completTotale">
                <tr>
                  <td class="theadTotale">
                    {{ $t("TOTAL_HT") }}
                  </td>
                  <td>{{ devisDetail.total_HT | financial }}</td>
                </tr>
                <tr v-if="devisDetail.remise_value">
                  <td class="theadTotale">
                    {{ $t("REMISE") }}
                  </td>
                  <td v-if="devisDetail.remise_mode == 'par_ligne'">
                    {{ devisDetail.remise_value | financial }}
                  </td>
                  <td v-else>
                    {{ devisDetail.remise_total | financial }}
                  </td>
                </tr>
                <tr v-if="devisDetail.remise_value">
                  <td class="theadTotale">
                    {{ $t("TOTALREMISE") }}
                  </td>
                  <td>{{ devisDetail.total_ht_with_remise | financial }}</td>
                </tr>
                <template v-if="devisDetail.tva_document.length">
                  <tr
                    v-for="tva in devisDetail.tva_document"
                    :key="'tva' + tva.id"
                  >
                    <td class="theadTotale">TVA ({{ tva.tva }}%)</td>
                    <td style="border-radius: 0px 5px 0px 0px">
                      {{ tva.tva_montant | financial }}
                    </td>
                  </tr>
                </template>
                <tr>
                  <td class="theadTotale">
                    {{ $t("Total TTC") }}
                  </td>
                  <td>{{ devisDetail.total_TTC | financial }}</td>
                </tr>
                <template v-if="devisDetail.devis.with_aide">
                  <tr v-if="+devisDetail.devis.montant_cee">
                    <td class="theadTotale">
                      {{ $t("MONTANT_CEE") }}
                    </td>
                    <td>{{ devisDetail.devis.montant_cee | financial }}</td>
                  </tr>
                  <tr v-if="+devisDetail.devis.montant_maprimrenov">
                    <td class="theadTotale">
                      {{ $t("MA_PRIME_RENOV") }}
                    </td>
                    <td>
                      {{ devisDetail.devis.montant_maprimrenov | financial }}
                    </td>
                  </tr>
                </template>

                <tr v-if="devisDetail.total_to_pay">
                  <td class="theadTotale">
                    {{ $t("NET à payer") }}
                  </td>
                  <td>{{ devisDetail.total_to_pay | financial }}</td>
                </tr>
              </table>
            </div>
          </div>
          <div class="part">
            <h3 v-if="devisDetail.devis.remarque" class="remarqueblock">
              <span class="part-title"> {{ $t("REMARQUE") }} : </span>
              <span>
                {{
                  redremarque
                    ? devisDetail.devis.remarque
                    : devisDetail.devis.remarque.slice(0, 200)
                }}</span
              >
              <span
                class="read"
                v-if="devisDetail.devis.remarque.length > 200"
                @click="redremarque = !redremarque"
              >
                ...{{ redremarque ? $t("LESS") : $t("MORE") }}
              </span>
            </h3>
            <h3 v-if="devisDetail.devis.conditions_reglements">
              <span class="part-title"> {{ $t("COND_REG") }}: </span>
              <span>
                {{
                  redconditionreglements
                    ? devisDetail.devis.conditions_reglements
                    : devisDetail.devis.conditions_reglements.slice(0, 90)
                }}</span
              >
              <span
                class="read"
                v-if="devisDetail.devis.conditions_reglements.length > 90"
                @click="redconditionreglements = !redconditionreglements"
              >
                ...{{ redconditionreglements ? $t("LESS") : $t("MORE") }}
              </span>
            </h3>
            <h3>
              <span class="part-title"> {{ $t("MOYENPAIEMENT") }}: </span>

              <span v-if="devisDetail.devis.moyens_paiement == 'financement'">{{
                $t("FINANCEMENT")
              }}</span>
              <span v-else>{{ $t("Cheque_virement_espece") }}</span>
            </h3>
            <!-- <h3>{{ $t("INFO_BANK") }}</h3>
            <h3>
              {{ $t("IBAN") }} : <span>{{ devisDetail.entreprise.IBAN }}</span>
            </h3>
            <h3>
              {{ $t("SWIFT_BIC") }} :
              <span> {{ devisDetail.entreprise.SWIFT_BIC }} </span>
            </h3> -->
            <div v-if="devisDetail.devis.moyens_paiement == 'financement'">
              <div class="cadre">
                <h3 v-if="devisDetail.devis.organisme_preteur">
                  <span class="part-title"> {{ $t("ORG_PRETEUR") }}:</span>

                  <span>{{ devisDetail.devis.organisme_preteur }}</span>
                </h3>
                <h3 v-if="devisDetail.devis.montant_credit">
                  <span class="part-title"> {{ $t("MONTANT_CREDIT") }}: </span>

                  <span>{{
                    devisDetail.devis.montant_credit | financial
                  }}</span>
                </h3>
                <h3 v-if="devisDetail.devis.taux_nominal">
                  <span class="part-title"> {{ $t("TEAUX_NOMINAL") }}: </span>

                  <span>{{ devisDetail.devis.taux_nominal }} %</span>
                </h3>
                <h3 v-if="devisDetail.devis.mensualite">
                  <span class="part-title"> {{ $t("MENSUALITE") }}: </span>

                  <span>{{ devisDetail.devis.mensualite | financial }}</span>
                </h3>
                <h3 v-if="devisDetail.devis.nombre_mensualites">
                  <span class="part-title"> {{ $t("NBR_MENSUALITE") }}: </span>

                  <span>{{ devisDetail.devis.nombre_mensualites }}</span>
                </h3>
                <h3 v-if="devisDetail.devis.taeg">
                  <span class="part-title"> {{ $t("TAEG") }}:</span>
                  <span>{{ devisDetail.devis.taeg }} %</span>
                </h3>
                <h3 v-if="devisDetail.devis.cout_total_credit">
                  <span class="part-title"> {{ $t("CRIDIT_TOTALE") }}:</span>

                  <span>{{
                    devisDetail.devis.cout_total_credit | financial
                  }}</span>
                </h3>
              </div>
              <h3 v-if="devisDetail.devis.assurance_type">
                <span class="part-title"> {{ $t("ASSURANCE_TYPE") }}</span>
                :
                <span>{{ devisDetail.devis.assurance_type }}</span>
              </h3>
            </div>

            <h3
              v-if="
                +devisDetail.devis.montant_maprimrenov &&
                devisDetail.devis.with_aide
              "
            >
              <span class="part-title"> {{ $t("MA_PRIME_RENOV") }}: </span>

              <span>{{
                devisDetail.devis.montant_maprimrenov | financial
              }}</span>
            </h3>
            <h3
              v-if="
                +devisDetail.devis.montant_cee && devisDetail.devis.with_aide
              "
            >
              <span class="part-title"> {{ $t("MONTANT_CEE") }}: </span>

              <span>{{ devisDetail.devis.montant_cee | financial }}</span>
            </h3>

            <h3
              v-if="devisDetail.devis.bareme_mpr && devisDetail.devis.with_aide"
            >
              <span class="part-title"> {{ $t("Barème MPR") }}:</span>

              <span v-if="devisDetail.devis.bareme_mpr == 'blue'">
                {{ $t("BLEU") }}
              </span>
              <span v-else-if="devisDetail.devis.bareme_mpr == 'yellow'">
                {{ $t("YELLOW") }}
              </span>
              <span v-else-if="devisDetail.devis.bareme_mpr == 'purple'">
                {{ $t("PURPLE") }}
              </span>
              <span v-else-if="devisDetail.devis.bareme_mpr == 'pink'">
                {{ $t("PINK") }}
              </span>
            </h3>
          </div>
        </div>

        <template #modal-footer>
          <div class="buttondevis" v-if="!devisDetail.brouillon">
            <b-button
              variant="success"
              @click="signed()"
              v-if="
                (isSuperAdmin ||
                  isentrepriseAdmin ||
                  isAssistant ||
                  isCommercial) &&
                devisDetail.is_signed != 'signed'
              "
            >
              <div class="block-spinner">
                {{ $t("YOUSIGN") }}
                <div v-if="signedLoader" class="loading ml-2">
                  <div class="spinner-border" role="status"></div>
                </div>
              </div>
            </b-button>
            <b-button
              variant="success"
              @click="sendEmail()"
              v-if="
                isSuperAdmin || isentrepriseAdmin || isAssistant || isCommercial
              "
            >
              <div class="block-spinner">
                {{ $t("SEND_BY_EMAIL") }}
              </div>
            </b-button>

            <b-button
              variant="success"
              @click="openfac()"
              v-if="
                devisDetail.devis.facture &&
                devisDetail.devis.facture.status == 'confirmed'
              "
            >
              <div class="block-spinner">
                {{ devisDetail.devis.facture.numerofactures }}
              </div>
            </b-button>

            <b-button variant="success" @click="choixfac()" v-else>
              <div class="block-spinner">
                {{ $t("INVOICE_GENERATE") }}
              </div>
            </b-button>

            <b-button variant="danger" @click="generate()">
              <div class="block-spinner">
                {{ $t("DOWNLOAD") }}
                <div v-if="downloadLoader" class="loading ml-2">
                  <div class="spinner-border" role="status"></div>
                </div>
              </div>
            </b-button>
          </div>
          <div class="buttondevis" v-else>
            <b-button
              variant="danger"
              v-if="
                isSuperAdmin || isentrepriseAdmin || isAssistant || isCommercial
              "
              @click="createDevis"
            >
              <div class="block-spinner">
                {{ $t("TRANSFERER") }} {{ $t("ESTIMATE") }}
                <div
                  class="spinner-border ml-2"
                  role="status"
                  v-if="getdevisLoading"
                ></div>
              </div>
            </b-button>
          </div>
        </template>
      </b-modal>
      <!--------------------------------------- END DETAILS_DEVIS --------------------------------------->

      <!------------------------------------------------- delete devis ------------------------------------------------->

      <deleteModalVue
        v-if="devisToDelete"
        :loader="getdevisLoading"
        :elemToDelete="devisToDelete.devis"
        :elemDelete="delete_devis"
        @updateList="setup(false)"
      ></deleteModalVue>
      <!------------------------------------------------- delete devis ------------------------------------------------->
      <b-modal size="lg" centered id="ModelError" ref="ModelError">
        <template #modal-header="{ close }">
          <h5>{{ $t("ERROR") }}</h5>
          <b-button size="sm" @click="close()">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17.028"
              height="17.028"
              viewBox="0 0 17.028 17.028"
            >
              <path
                id="Icon_material-close"
                data-name="Icon material-close"
                d="M24.528,9.215,22.813,7.5l-6.8,6.8-6.8-6.8L7.5,9.215l6.8,6.8-6.8,6.8,1.715,1.715,6.8-6.8,6.8,6.8,1.715-1.715-6.8-6.8Z"
                transform="translate(-7.5 -7.5)"
                fill="#fff"
              />
            </svg>
          </b-button>
        </template>
        <div class="messageError" v-if="erreur">{{ erreur }}</div>
        <template #modal-footer>
          <div class="actionModel">
            <b-button variant="danger" @click="hideModal('ModelError')">
              <div class="block-spinner">
                {{ $t("CLOSE") }}
              </div>
            </b-button>
          </div>
        </template>
      </b-modal>

      <b-pagination
        v-model="page"
        :total-rows="getTotalRowdevis"
        :per-page="perPage"
        aria-controls="my-table"
        pills
        last-number
        first-number
        align="center"
        size="sm"
        @change="pagination"
        class=""
      ></b-pagination>
    </div>

    <div class="text-center center-spin">
      <b-spinner
        v-if="getdevisLoading"
        variant="primary"
        label="Spinning"
        class="big-spin"
      ></b-spinner>
    </div>
    <b-modal id="modal-1" ref="modalefact" title="Facture Type" @hide="close">
      <form @submit.prevent="facture">
        <div class="center">
          <b-form-group :label="$t('TYPE-FAC')">
            <b-form-select
              v-model="fac.type"
              :options="factypeoption"
            ></b-form-select>
          </b-form-group>
          <b-form-group
            :label="$t('TOT-TYP')"
            v-if="fac.type == 'facture.acompte'"
          >
            <b-form-select
              v-model="fac.total_type"
              :options="factotalType"
            ></b-form-select>
          </b-form-group>
          <b-form-group
            :label="$t('VALEUR')"
            v-if="fac.total_type == 'ht' && fac.type == 'facture.acompte'"
          >
            <b-form-input
              type="number"
              placeholder="Écrire la valeur de total type"
              v-model="v$.fac.restht.$model"
              :state="validateState('restht')"
              aria-describedby="restht-feedback"
            ></b-form-input>
            <error-handle
              :list="v$.fac.restht.$errors"
              id="restht-feedback"
            ></error-handle>
          </b-form-group>
          <b-form-group
            :label="$t('VALEUR')"
            v-if="fac.total_type == 'ttc' && fac.type == 'facture.acompte'"
          >
            <b-form-input
              type="number"
              placeholder="Écrire la valeur de total type"
              v-model="v$.fac.restttc.$model"
              :state="validateState('restttc')"
              aria-describedby="restttc-feedback"
            ></b-form-input>
            <error-handle
              :list="v$.fac.restttc.$errors"
              id="restttc-feedback"
            ></error-handle>
          </b-form-group>
          <b-form-group
            :label="$t('VALEUR')"
            v-if="
              fac.total_type == 'pourcentage' && fac.type == 'facture.acompte'
            "
          >
            <b-form-input
              type="number"
              placeholder="Écrire la valeur de total type"
              v-model="v$.fac.restttc_pourcentage.$model"
              :state="validateState('restttc_pourcentage')"
              aria-describedby="restttc_pourcentage-feedback"
            ></b-form-input>
            <error-handle
              :list="v$.fac.restttc_pourcentage.$errors"
              id="restttc_pourcentage-feedback"
            ></error-handle>
          </b-form-group>
        </div>
      </form>
      <template #modal-footer>
        <div class="double">
          <b-button variant="danger" @click="resetFacture">
            <div class="block-spinner">
              {{ $t("ANNULER") }}
            </div>
          </b-button>
          <b-button variant="success" @click="facture()">
            <div class="block-spinner">
              {{ $t("CONFIRM") }}
              <div v-if="getFactureLoading" class="loading ml-2">
                <div class="spinner-border" role="status"></div>
              </div>
            </div>
          </b-button>
        </div>
      </template>
    </b-modal>

    <editModal
      v-if="devisToConfig"
      :devis="devisToConfig"
      @updateList="setup(false)"
      @closeUpdate="resetDevis($event)"
    />
    <CommentMarche to="/how-it-work/devis"></CommentMarche>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { domains } from "@/environment";
import moment from "moment";
import searchInput from "../components/ui/searchInput.vue";
import deleteModalVue from "../components/ui/deleteModal.vue";
import formatMoney from "accounting-js/lib/formatMoney.js";
import searchBar from "../components/ui/searchBar.vue";
import { useVuelidate } from "@vuelidate/core";
import {
  numeric,
  minValue,
  maxValue,
  requiredIf,
  and,
} from "@vuelidate/validators";
import errorHandle from "../components/ui/errorHandle.vue";
import CommentMarche from "../components/commentMarche.vue";

export default {
  setup() {
    return { v$: useVuelidate({ $lazy: true, $autoDirty: true }) };
  },
  components: {
    searchBar,
    searchInput,
    deleteModalVue,
    editModal: () => import("@/components/ui/editDevis"),
    errorHandle,
    CommentMarche,
  },
  data() {
    return {
      current_ttc: null,
      current_devis: null,
      current_devis_type: null,
      getAllTypedevis: [
        { id: 1, label: "libre" },
        // { id: 2, label: "BAR-TH-101" },
        // { id: 3, label: "BAR-TH-112" },
        // { id: 4, label: "BAR-TH-113" },
        // { id: 5, label: "BAR-TH-127" },
        // { id: 6, label: "BAR-TH-129" },
        // { id: 7, label: "BAR-TH-137" },
        // { id: 8, label: "BAR-TH-143" },
        // { id: 9, label: "BAR-TH-148" },
        // { id: 10, label: "BAR-TH-171" },
        // { id: 11, label: "BAR-TH-174" },
        // { id: 12, label: "BAR-TH-175" },
        {id:  13, label: "BAR-EN-101" },
        {id:  14, label: "BAR-EN-102" },
        {id:  15, label: "BAR-EN-103" },
        {id:  17, label: "BAR-EN-104" },
        // {id:  16, label: "BAR-TH-173" },
      ],
      fac: {
        type: "facture.fin-travaux",
        total_type: "pourcentage",
        restht: null,
        restttc: null,
        restttc_pourcentage: null,
      },
      readActivated: [
        {
          bool: false,
        },
      ],
      showGroup: [
        {
          bool: true,
        },
      ],
      group_type_prestation: null,
      type_prestation: ["TypePrestation.Fourniture"],
      factypeoption: [
        { value: "facture.acompte", text: "Facture Acompte" },
        { value: "facture.fin-travaux", text: "Facture Fin Travaux" },
      ],
      factotalType: [
        { value: "pourcentage", text: "Acompte Pourcentage" },
        { value: "ttc", text: "Acompte ttc" },
      ],
      current_brouillon: null,
      filtre_brouillon: null,
      current_entreprise: null,
      filtre_entreprise: null,
      erreur: null,
      start: null,
      end: null,
      context: null,
      search: null,
      devisToDelete: null,
      devisToConfig: null,
      devisDetail: null,
      page: 1,
      perPage: 10,
      pdfInfo: null,
      downloadUrl: domains.download,
      searchBlock: false,
      brouillon: null,
      box: "",
      reddescription: false,
      redremarque: false,
      redconditionreglements: false,
      signedLoader: false,
      downloadLoader: false,
      editLoader: false,
      filtre_client: null,
      filtre_devis: null,
      currentclient: null,
      showAlert: false,
    };
  },
  validations() {
    return {
      fac: {
        restttc_pourcentage: {
          requiredIf: requiredIf(
            and(
              this.fac.type == "facture.acompte",
              this.fac.total_type == "pourcentage"
            )
          ),
          numeric,
          minValue: minValue(1),
          maxValue: maxValue(100),
        },
        restht: {
          requiredIf: requiredIf(
            and(this.fac.type == "facture.acompte", this.fac.total_type == "ht")
          ),
          numeric,
          minValue: minValue(1),
          maxValue: maxValue(100000),
        },
        restttc: {
          requiredIf: requiredIf(
            and(
              this.fac.type == "facture.acompte",
              this.fac.total_type == "ttc"
            )
          ),
          numeric,
          minValue: minValue(1),
          maxValue: maxValue(100000),
        },
      },
    };
  },
  methods: {
    ...mapActions([
      "updateCreationEntreprise",
      "all_users",
      "allpiece",
      "delete_devis",
      "devis",
      "signed_devis",
      "devis_client",
      "downloadDevis",
      "duplicateDevis",
      "store_facture",
      "getonedevis",
      "email_devis",
      "update_devis_group",
      "alltravaux",
      "all_entreprises",
      "allDelegataire",
      "transform_brouillon",
      "all_clients",
    ]),
    handleClick(data) {
      if (data.brouillon) {
        this.showAlert = true;

        setTimeout(() => {
          this.showAlert = false;
        }, 2000);
      } else {
        this.generate(data);
      }
    },
    goToRoute(route) {
      this.$router.push(route);
    },
    onContext(ctx) {
      this.context = ctx;
    },

    sortBy(column, order) {
      if (column === "brouillon") {
        this.current_brouillon = order;
      }
      this.fetchSortedData();
    },
    fetchSortedData() {
      this.devis({
        page: this.page,
        per_page: this.perPage,
        entreprise: this.getOnlineUser.entreprise,
        brouillon: this.current_brouillon,
        client: this.currentclient ? this.currentclient.id : null,
        type_devis: this.current_devis_type,
      });
    },

    handleStore() {
      this.$router.push("/devis/devisTypes");
    },

    moment: function (date) {
      return moment(new Date(date)).locale(this.getSelectedLang.iso_code);
    },

    closeGroup(x) {
      this.showGroup[x].bool = !this.showGroup[x].bool;
    },
    selectentreprise(query) {
      if (query) {
        this.filtre_entreprise = query;
      } else {
        this.filtre_entreprise = {
          lib: null,
        };
      }
    },

    hideModal(ref) {
      this.$refs[ref].hide();
    },
    resetDate() {
      this.start = null;
      this.end = null;
      this.devis({
        page: this.page,
        per_page: this.perPage,
        search: this.search,
        brouillon: this.brouillon,
      });

      this.$refs["Filtre"].hide();
    },

    closeFilter() {
      this.devis({
        page: this.page,
        per_page: this.perPage,
        search: this.search,
        start_date: this.start,
        end_date: this.end,
        brouillon: this.brouillon,
      });
      this.$refs["Filtre"].hide();
    },
    handleDelete(CAT) {
      this.devisToDelete = { ...CAT };
      this.$root.$emit("bv::show::modal", "ModelDelete");
    },
    handleCopy(CAT) {
      this.duplicateDevis(CAT.devis.id)
        .then(() => {
          this.devis({
            page: this.page,
            per_page: this.perPage,
            search: this.search,
          });
        })
        .catch(() => {
          this.$refs["ModelError"].show();
        });
    },

    pagination(paginate) {
      this.page = paginate;
      if (this.isSuperAdmin)
        this.devis({
          page: this.page,
          per_page: this.perPage,
          search: this.search,
          brouillon: this.current_brouillon,
          entreprise: this.current_entreprise,
          type_devis: this.current_devis_type,
        });
      else
        this.devis({
          page: this.page,
          per_page: this.perPage,
          search: this.search,
          entreprise: this.getOnlineUser.entreprise,
          brouillon: this.current_brouillon,
          type_devis: this.current_devis_type,
        });
    },

    updateSearch(s) {
      this.page = 1;
      this.search = s;
    },

    openfac() {
      var facture = this.devisDetail.devis.facture;
      this.$router.push("/facture/list?id=" + facture.id);
    },

    handleDevis(CAT) {
      this.devisDetail = null;
      this.devisDetail = { ...CAT };

      this.devisDetail.devis.fin_devis = moment(
        this.devisDetail.devis.fin_devis
      ).format("YYYY-MM-DD");
      this.creategroup();
      this.allDelegataire({
        entreprise_id: this.devisDetail.entreprise.id,
      });
      if (
        this.isSuperAdmin ||
        this.isentrepriseAdmin ||
        this.isAssistant ||
        this.isCommercial
      )
        this.all_users({
          entreprise_id: this.devisDetail.entreprise.id,
          page: 1,
          per_page: 999,
        });
  
      if (["BAR-EN-101", "BAR-EN-102", "BAR-EN-104","BAR-EN-103","Type Complexe", "BAR-TH-101","BAR-TH-112","BAR-TH-113","BAR-TH-143","BAR-TH-171",
        "BAR-TH-127","BAR-TH-129" ,"BAR-TH-148","BAR-TH-137","BAR-TH-173","Libre","BAR-TH-174","BAR-TH-175"
      ].includes(this.devisDetail.devis.type_devis)) {
  this.$router.push("/devis/view/BAR/" + this.devisDetail.devis.id);
}


     else {
        this.$refs["Modeldevis"].show();
      }
    },
    async signed() {
      if (this.devisDetail.is_signed != "pending") {
        this.signedLoader = true;
        await this.signed_devis({
          id: this.devisDetail.devis.id,
          doc: this.devisDetail.id,
        })
          .then(() => {
            this.box = "";
            this.signedLoader = false;
            this.$bvModal.msgBoxOk(this.$t("YOUSIGN_SENDED"), {
              title: "Confirmation",
              size: "sm",
              buttonSize: "sm",
              okVariant: "success",
              headerClass: "p-2 border-bottom-0",
              centered: true,
            });
            this.hideModal("Modeldevis");
          })
          .catch(() => {
            this.signedLoader = false;
            this.erreur = this.getErreur;
            this.$refs["ModelError"].show();
          });
      } else {
        this.showMsgBox();
      }
    },

    creategroup() {
      for (var i = 0; i < this.devisDetail.groupeLigneDocument.length; i++) {
        this.showGroup.push({
          bool: true,
        });
        this.readActivated.push({
          bool: false,
        });
      }
    },

    generate(devis) {
      this.downloadLoader = true;
      var id = 0;
      if (!devis) {
        id = this.devisDetail.devis.id;
      } else {
        id = devis.devis.id;
      }
      this.downloadDevis(id)
        .then((response) => {
          this.downloadLoader = false;
          this.pdfInfo = response;
          this.download();
        })
        .catch(() => {
          this.downloadLoader = false;
          this.$refs["ModelError"].show();
        });
    },
    choixfac() {
      this.$refs["modalefact"].show();
    },
    close(bv) {
      if (bv.trigger == "backdrop") {
        bv.preventDefault();
        this.box = "";
        this.$bvModal
          .msgBoxConfirm(`${this.$t("CLOSE")}`, {
            title: `${this.$t("CONFIRM")}`,
            size: "sm",
            buttonSize: "sm",
            okVariant: "success",
            cancelVariant: "danger",
            okTitle: `${this.$t("YES")}`,
            cancelTitle: `${this.$t("NO")}`,
            footerClass: "p-2",
            hideHeaderClose: false,
          })
          .then((value) => {
            if (value) {
              this.resetFacture();
            }
          });
      }
    },

    validateState(name) {
      const { $dirty, $error } = this.v$.fac[name];
      return $dirty ? !$error : null;
    },

    resetFacture() {
      this.fac = {
        type: "facture.fin-travaux",
        total_type: "pourcentage",
        valeur: null,
        restht: null,
        restttc: null,
        restttc_pourcentage: null,
      };
      this.$refs["modalefact"].hide();
    },

    facture() {
      const { restht, restttc, restttc_pourcentage } = this.v$.fac;

      restht.$validate();
      restttc.$validate();
      restttc_pourcentage.$validate();

      if (this.fac.type == "facture.acompte") {
        if (this.fac.total_type == "pourcentage" && restttc_pourcentage.$error)
          return;
        if (this.fac.total_type == "ht" && restht.$error) return;
        if (this.fac.total_type == "ttc" && restttc.$error) return;
      }

      var facture = {
        document_id: this.devisDetail.id,
        modele_id: this.devisDetail.devis.modele.id,
        conditions_reglements: this.devisDetail.devis.conditions_reglements,
        remarque: this.devisDetail.devis.remarque,
        type_facture: this.fac.type,
      };
      if (this.fac.type == "facture.acompte") {
        if (this.fac.total_type == "pourcentage") {
          if (this.fac.restttc_pourcentage == 100) {
            facture.type_facture = "facture.fin-travaux";
          } else {
            facture.total_percent = this.fac.restttc_pourcentage;
            facture.total_type = this.fac.total_type;
          }
        } else if (this.fac.total_type == "ht") {
          facture.total_type = this.fac.total_type;
          facture.total_HT = this.fac.restht;
        } else {
          facture.total_type = this.fac.total_type;
          facture.total_TTC = this.fac.restttc;
        }
      }

      this.store_facture(facture)
        .then((response) => {
          if (response) {
            const facture = response.data.data.facture;
            if (facture) {
              this.$router.push("/facture/list?id=" + facture.id);
            } else {
              this.$router.push("/facture/list");
            }
          } else alert("Erreur !");
        })
        .catch(() => {
          this.erreur = this.getFactureError;
          this.$refs["ModelError"].show();
        });
    },
    download() {
      if (this.pdfInfo.document.is_signed == "stored")
        window.open(this.pdfInfo.pdf, "_blank");
      else window.open(this.downloadUrl + this.pdfInfo.pdf, "_blank");
    },

    sendEmail() {
      this.email_devis(this.devisDetail.devis.id)
        .then(() => {
          this.box = "";
          this.$bvModal.msgBoxOk(this.$t("INVOICE_SENDED"), {
            title: "Confirmation",
            size: "sm",
            buttonSize: "sm",
            okVariant: "success",
            headerClass: "p-2 border-bottom-0",
            centered: true,
          });
          this.hideModal("Modeldevis");
        })
        .catch(() => {
          this.$refs["ModelError"].show();
        });
    },

    showMsgBox() {
      this.box = "";
      this.$bvModal
        .msgBoxConfirm(`${this.$t("YOUSIGN_SENDED_WARNIN")}`, {
          title: `${this.$t("CONFIRM")}`,
          size: "sm",
          buttonSize: "sm",
          okVariant: "success",
          cancelVariant: "danger",
          okTitle: `${this.$t("YES")}`,
          cancelTitle: `${this.$t("NO")}`,
          footerClass: "p-2",
          hideHeaderClose: false,
        })
        .then((value) => {
          if (value) {
            this.signed_devis({
              id: this.devisDetail.devis.id,
              doc: this.devisDetail.id,
            })
              .then(() => {
                this.box = "";
                this.$bvModal.msgBoxOk(this.$t("YOUSIGN_SENDED"), {
                  title: "Confirmation",
                  size: "sm",
                  buttonSize: "sm",
                  okVariant: "success",
                  headerClass: "p-2 border-bottom-0",
                  centered: true,
                });
                this.hideModal("Modeldevis");
              })
              .catch(() => {
                this.erreur = this.getErreur;
                this.$refs["ModelError"].show();
              });
          }
        });
    },

    createDevis() {
      var produits = [];

      this.devisDetail.groupeLigneDocument.forEach((group) => {
        this.devisDetail.GroupeLigneDocument_produit.forEach((produit) => {
          if (produit.groupelignedocument_id == group.id)
            produits.push(produit);
        });
        group.produits = produits;
        produits = [];
      });

      var devis = {
        id: this.devisDetail.id,
        entreprise_id: this.devisDetail.entreprise.id,
        client_id: this.devisDetail.client.id,
        modele_id: this.devisDetail.devis.modele.id,
        brouillon: 0,
        groupes: this.devisDetail.groupeLigneDocument,
      };

      this.transform_brouillon(devis)
        .then(() => {
          this.setup();
          this.hideModal("Modeldevis");
        })
        .catch(() => {
          this.handleUpdate();
        });
    },
    async handleUpdate() {
      this.editLoader = true;
      await this.getonedevis(this.devisDetail.devis.id)
        .then(() => {
          this.editLoader = false;
          this.devisToConfig = this.getdevis;
          // this.$root.$emit("bv::show::modal", "devisModal");
        })
        .catch(() => {
          this.editLoader = true;
        });
    },

    resetDevis() {
      this.devisToConfig = null;
    },
    selectClient(query) {
      this.filtre_client = query;
    },
    selectDevis(query) {
      this.filtre_devis = query;
    },
    totalHT(item) {
      const totalSansRemise = item.unit_price * item.quantite;
      if (Math.round(totalSansRemise * 100) / 100 < 0) return 0;
      return Math.round(totalSansRemise * 100) / 100;
    },

    totalAR(item) {
      const totalSansRemise = this.totalHT(item);
      var totalAvecRemise = totalSansRemise;
      if (item.remise_type == "remise.HT")
        totalAvecRemise -= item.remise_value ? +item.remise_value : 0;
      else totalAvecRemise -= (item.remise_value * totalSansRemise) / 100;

      if (totalAvecRemise < 0) return 0;
      return Math.round(totalAvecRemise * 100) / 100;
    },

    setup(refresh = false) {
      if (refresh) this.page = 1;
      this.search = null;
      this.resetDevis();
      this.$refs["Modeldevis"].hide();

      this.current_entreprise = this.filtre_entreprise;
      this.current_brouillon = this.filtre_brouillon;
      this.currentclient = this.filtre_client;
      this.current_devis_type = this.filtre_devis;
      this.$refs["entrepriseModal"].hide();

      if (this.isSuperAdmin) {
        this.devis({
          page: this.page,
          per_page: this.perPage,
          entreprise: this.current_entreprise,
          brouillon: this.current_brouillon,
          client: this.currentclient ? this.currentclient.id : null,
          type_devis: this.current_devis_type,
        });
      } else {
        this.devis({
          page: this.page,
          per_page: this.perPage,
          entreprise: this.getOnlineUser.entreprise,
          brouillon: this.current_brouillon,
          client: this.currentclient ? this.currentclient.id : null,
          type_devis: this.current_devis_type,
        });
      }
    },
  },

  mounted() {
    this.setup(true);
    if (this.isSuperAdmin)
      this.all_entreprises({ page: this.page, per_page: this.perPage });

    if (this.$route.query.id) {
      this.getonedevis(this.$route.query.id).then((response) => {
        if (response) this.handleDevis(this.getdevis);
        else alert("Devis Not Found !");
      });
    }
    this.all_clients({ page: 1, per_page: 1000 });
    this.fetchSortedData();
  },

  computed: {
    ...mapGetters([
      "getdevisLoading",
      "getTotalRowdevis",
      "getTabs",
      "getAlldevis",
      "getOnlineUser",
      "getdevis",
      "getFactureLoading",
      "getSelectedLang",
      "getAllentreprises",
      "getentrepriseLoading",
      "getErreur",
      "getFactureError",
      "getAllclients",
      "getclientLoading",
    ]),
    isSuperAdmin() {
      return this.getOnlineUser.role == "user.super-admin";
    },
    isentrepriseAdmin() {
      return this.getOnlineUser.role == "user.entreprise-admin";
    },
    isAssistant() {
      return this.getOnlineUser.role == "user.assistant";
    },
    isCommercial() {
      return this.getOnlineUser.role == "user.commercial-entreprise";
    },
    isClient() {
      return this.getOnlineUser.role == "user.client";
    },
    searchBarText() {
      return `${this.$t("SEARCH_BY_CLIENT")} ( ${this.$t("NAME")}, ${this.$t(
        "SURNAME"
      )}, ${this.$t("EMAIL")},${this.$t("Total TTC")})`;
    },
  },

  filters: {
    financial: function (value, noSymbol = false) {
      return formatMoney(value, {
        symbol: noSymbol ? "" : "€",
        thousand: " ",
        precision: 2,
        decimal: ",",
        format: "%v %s",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.inner-container .page-header button {
  margin-bottom: 0 !important;
  color: #fff;

}
.dropdown-btn {
  background-color: #28367a;
  text-align: center;
  padding: 10px 80px;
  margin-left: 20px;
  margin-right: 12px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}
.title{
  font-size: 18px;
      font-weight: 800;
      border: none;
      border-radius: 5px;
      box-shadow: 1px 1px 4px 0px #15223214;
}
.dropdown-content {
  cursor: pointer;
  display: none;
  position: absolute;
  top: 100%; /* Juste en dessous du bouton */
  left: 50%; /* Centre horizontalement par rapport au bouton */
  transform: translateX(-50%); /* Centre exactement le menu */
  background-color: #f9f9f9;
  min-width: 200px; /* Largeur minimale du menu */
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}
.dropdown-content a {
  text-align: center;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}
.sort-arrow {
  cursor: pointer;
  color: grey;
  padding: 0 5px;
  font-size: 14px;
}

.sort-arrow:hover {
  color: white;
}

.active-sort {
  color: white;
  font-weight: bold;
}

.piece {
  background-color: #28367a;
  color: #fff;
  padding: 0px 15px;
  border-radius: 5px 5px 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  div:first-child {
    display: flex;
    align-items: center;
    overflow: hidden;
  }

  p {
    margin: 0;
    font-size: 14px;
    margin-right: 8px;
    overflow: hidden;
  }
  .icone-down-up {
    display: flex;
    align-items: center;
    p,
    span {
      @media only screen and (max-width: 1000px) {
        display: none;
      }
    }

    button {
      background-color: transparent !important;
      border: transparent;
      box-shadow: none;
    }

    span {
      font-size: 14px;
      background-color: #6472b3;
      border-radius: 4px;
      padding: 2px 12px;
    }
  }
}

.group_financement {
  width: 100%;
  padding: 8px 12px;
  background-color: #eef1fd;
  border-radius: 5px;
}
.svg-inline--fa {

    margin: inherit;
}
.grey {
  background-color: #f5f6f8;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 32px;
  margin: 0 auto;
}

form .form-group {
  max-width: none !important;
}

.desclass {
  margin-top: 9px;
}
.cours {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  p {
    margin: 0;
    color: #fff;
    background-color: #d0cccc;
    width: 81px;
    height: 24px;
    font-size: 13px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  button {
    font-size: 14px;
  }
}
.content {
  margin-top: 12px;
  .content-header {
    .input-group {
      @media only screen and (max-width: 1000px) {
        width: 59%;
      }
      width: 80%;
      position: relative;
      margin-bottom: 0;
      .icon-search {
        @media only screen and (max-width: 1000px) {
          display: none;
        }
        overflow: visible;
        position: absolute;
        left: 15px;
        top: 10px;
        color: #515151;
      }

      .icon-balance {
        cursor: pointer;
        overflow: visible;
        position: absolute;
        right: 15px;
        top: 10px;
        color: #28367a;
        outline: 0;

        .balance {
          color: #28367a;
        }
      }
      .form-control::placeholder {
        color: #515151;
        font-size: 12px;
      }
    }

    .filtre {
      @media only screen and (max-width: 1000px) {
        width: 39%;
      }
      width: 19%;
      background-color: #28367a;
      color: #fff;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 8px 4px;
      border-radius: 5px;
      font-size: 12px;
    }
  }

  & p {
    font-size: 12px;
    font-weight: 500;
    margin: 8px 15px 0 0;
  }
}

.body {
  padding: 15px;
  box-shadow: 1px 1px 24px #00000019;
  max-height: 60vh;
  overflow: auto;

  ul {
    list-style: none;
    & li {
      padding: 8px 0;
      cursor: pointer;
      background-color: #f8f8f8;
      margin-bottom: 4px;
      color: #000;
      border: none;
      box-shadow: none;
      transition: all 0.2s ease;
      text-align: center;

      &:hover {
        background-color: #28367a;
        color: #fff;
      }
    }
  }
}

.w-100 {
  background-color: #f8f8f8;
  padding: 4px;
  overflow-x: auto;
}

body {
  background: #fafdff;
}
.justify {
  padding: 13px;
  .left {
    h2 {
      color: #28367a;
      font-weight: 700;
      margin-bottom: 0px;
    }
    .entrepriseinfo {
      color: #000;
      font-weight: 600;
    }
    .title {
      color: #28367a;
      font-weight: 700;
    }
    .adressinfo {
      display: grid;
    }
    .info {
      color: #515151;
    }
    .block {
      margin-top: 10px;
    }
  }
  .right {
    .span {
      color: #515151;
    }
    h2 {
      color: #28367a;
      font-weight: 700;
    }
    .adressinfo {
      display: grid;
    }
    .info {
      color: #515151;
    }
    .entrepriseinfo {
      color: #000;
      font-weight: 600;
    }
  }
}

.divborder {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  overflow: hidden;
  color: #28367a;
  width: 100%;
  p {
    margin: 0px 10px;
  }
}
.divborder::after,
.divborder::before {
  content: "";
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
  height: 1px;
  background-color: #d8d8d8;
}
.part .table td.blocklibdesc {
  text-align: left;

  .descblock {
    white-space: normal;
    max-width: 400px;
    height: 72px;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.575;
    position: relative;
    // display: -webkit-box;
    // -webkit-line-clamp: 3;
    // -webkit-box-orient: vertical;
  }
  .read {
    bottom: -2px;
    right: 0;
    display: inline-block;
    font-size: 12px;
    font-weight: 800;
    color: #28367a;
  }
}

.detailClient {
  background-color: #fff;
  box-shadow: 1px 1px 24px #00000019;
  .clientTop {
    padding: 16px 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-bottom: 1px solid #707070;

    & .prodimg {
      max-width: 185px;
      max-height: 231px;
    }

    .star {
      color: #ffc400;
      position: absolute;
      top: 12px;
      right: 8px;
      font-size: 30px;
    }
  }

  .clientMid {
    padding: 16px 8px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    border-bottom: 1px solid #707070;
    & > div {
      width: 50%;
    }
  }

  .clientBot {
    padding: 16px 8px;
  }

  & h4 {
    color: #28367a;
    font-size: 18px;
    font-weight: 600;
    margin: 0;
  }

  & p {
    color: #515151;
    font-size: 16px;
    font-weight: 500;
  }
}
.filtre {
  display: flex;
  align-items: center;
  gap: 6px;

  .productTrie {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    font-weight: 500;
    overflow: hidden;
    padding: 4px 8px;
    border-radius: 32px;
    background-color: #34c38f;
    color: #fff;
    margin: 2px 0 !important;

    .icons {
      cursor: pointer;
      width: 18px;
      height: 18px;
      margin-left: 4px;
    }
  }
}

.justify {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.part {
  padding: 8px 0;
  span {
    font-size: 14px;
    font-weight: 500;
    color: #515151;
    word-wrap: break-word;
  }
  .remarqueblock {
    margin-bottom: 5px;
  }
  .cadre {
    background-color: #f8f8f8;
    margin-top: 1%;
    margin-bottom: 1%;
    box-shadow: 0 2px 6px rgb(0 0 0 / 10%);
    padding: 13px;
  }
  .left {
    @media only screen and (max-width: 1000px) {
      width: 100%;
    }
    width: 30%;
  }

  .right {
    @media only screen and (max-width: 1000px) {
      width: 100%;
    }
    width: 23%;
    p {
      float: right;
      padding: 8px;
      background-color: #d0cccc;
      color: #000;
      width: 50%;
      font-weight: 600;
      text-align: center;
      margin: 0 0 4px;
    }
  }

  .fact {
    font-size: 16px;
    color: #28367a;
  }

  h2,
  h3 {
    font-weight: 600;
  }

  h2 {
    font-size: 16px;
  }
  h3 {
    color: #515151;
    font-size: 16px;
    margin: 0;
  }
  .part-title {
    color: #28367a;
    font-weight: 700;
    margin-right: 10px;
  }

  ul {
    list-style: none;

    li {
      @media only screen and (max-width: 1000px) {
        justify-content: space-between;
      }
      display: flex;
      align-items: center;
      color: #515151;

      span {
        display: block;
        width: 174px;
        font-size: 14px;
        white-space: nowrap;
      }

      .title {
        @media only screen and (max-width: 900px) {
          font-size: 12px;
        }
        color: #393939;
        font-weight: 600;
        font-size: 14px;
        margin-right: 10px;
      }
    }

    .traveauxtitle {
      @media only screen and (max-width: 900px) {
        padding: 0;
      }
      padding: 0 15px;
    }
  }
  .flexEnd {
    display: flex;
    justify-content: space-between;
    .sousTraitant {
      p {
        color: #2167d9;
      }
    }
    .subtable {
      .complet {
        @media only screen and (max-width: 1000px) {
          margin-right: 10%;
          width: 100%;
        }
        border-collapse: separate;
        margin-right: 10%;
        width: 100%;
        height: 32px;
        text-align: center;
        font-size: 12px;
        font-weight: bold;
        tr {
          height: 24px;
        }

        .thead {
          @media only screen and (max-width: 1000px) {
            padding: 6px !important;
          }
          background-color: #6472b3;
          color: #fff;
          max-width: 118px;
          height: 32px;
        }
        td {
          color: #000;
          background-color: #e9eaf2;
          width: 118px;
        }
      }
    }
  }
  .tabledonne {
    overflow-x: auto;
    margin-bottom: 4px;
  }
  table {
    border-collapse: separate;
    th {
      font-weight: 400;
      font-size: 12px;
      background-color: #6472b3;
      padding: 4px;
      color: #fff;
      border: 1px solid #6472b3;
      text-align: center;
    }
    td {
      text-align: center;
      padding: 4px;
      background-color: #e9eaf2;
      .libblock {
        font-weight: 800;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    tr {
      height: 24px;
    }
  }
}
.group {
  @media only screen and (max-width: 1000px) {
    overflow: hidden;
    padding: 8px;
  }
  box-shadow: 0px 2px 6px #00000019;
  background-color: #fff;
  border-radius: 0 0 5px 5px;
  margin-bottom: 15px;
  .part {
    overflow: auto;
    padding: 15px;
    margin-bottom: 0;
  }
}

.spinner-border {
  width: 1.2rem;
  height: 1.2rem;
}
.groupEdit {
  border: 1px solid #707070;
  padding: 8px;
  border-radius: 5px;
  margin-bottom: 16px;
  box-shadow: 0 0 2px 0 #707070;

  .entete {
    font-weight: 400;
    font-size: 12px;
    background-color: #28367a;
    padding: 4px;
    color: #fff;
    text-align: center;
    margin-bottom: 4px;
    display: flex;
    justify-content: space-between;
  }
  .action {
    display: flex;
    align-content: center;
    .trash,
    .ajout {
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 6px;
      color: #fff;
      height: 30px;
      width: 40%;
      margin: auto auto 15px auto;
    }
    .trash {
      background-color: #e4261a;
    }
  }
}
.input-group {
  margin-bottom: 8px;
  .icon-search {
    position: absolute;
    left: 15px;
    top: 10px;
  }
}
.selectajout {
  margin-bottom: 15px;
  border: 1px solid #707070;
  list-style: none;
  width: 100%;
}
.selectajout li {
  padding: 6px 0;
  cursor: pointer;
  background-color: #f8f8f8;
  color: #000;
  border: none;
  box-shadow: none;
  transition: all 0.2s ease;
  text-align: center;
}
.selectajout li:hover {
  background-color: #28367a;
  color: #fff;
}
.footer button.modalmodif {
  background-color: #28367a;
  width: 100%;
}
.footerbutton {
  display: flex;
  width: 85%;
  justify-content: space-around;
  margin: auto;
}
.footerbutton button {
  margin-right: 5px;
}
.groupe_pvw {
  margin: 24px 0;
  border: 1px solid #e4271b;
  width: 100%;
  padding: 4px 8px;
  font-size: 14px;
  color: #28367a;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.icons-chevron-down {
  display: flex;
  float: right;
  margin-right: 7px;
}
.trash_group {
  display: flex;
  float: left;
  margin-left: 7px;
}
.flexEndTotale {
  display: flex;
  justify-content: flex-end;
  .subtableTotale {
    .completTotale {
      @media only screen and (max-width: 1000px) {
        margin-right: 10%;
        width: 100%;
      }
      border-collapse: separate;
      margin-right: 10%;
      width: 100%;
      height: 32px;
      text-align: center;
      font-size: 12px;
      font-weight: bold;
      tr {
        height: 24px;
      }

      .theadTotale {
        @media only screen and (max-width: 1000px) {
          padding: 6px !important;
        }
        background-color: #28367a;
        color: #fff;
        max-width: 118px;
        height: 32px;
      }
      td {
        color: #000;
        background-color: #e9eaf2;
        width: 135px;
      }
    }
  }
}

.buttondevis {
  @media only screen and (max-width: 1000px) {
    width: 100%;
    padding: 10px 0;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  width: 95%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 20px 0;
  border-top: 1px solid #d8d8d8;

  button {
    @media only screen and (max-width: 1000px) {
      min-width: 49%;
      max-width: 49%;
      font-size: 12px;
      margin-top: 4px;
      padding: 0;
    }
    min-width: 23%;
    height: 42px;
    font-size: 14px;

    // .loading {
    //   display: none;
    // }

    .block-spinner {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
.tableModifdevis fieldset.form-group {
  margin-bottom: 0px;
}
.sameline {
  display: flex;
  justify-content: space-around;
  margin-bottom: 10px;
  select {
    margin: 0 10px;
  }
}
.modal-test {
  width: 95%;
  margin: auto;
}
.fieldset {
  width: 100%;
  margin: 0 10px;
}
.description-container {
  font-size: 14px;
  color: #515151;
  word-wrap: break-word;
  .title-description {
    color: #28367a;
    font-weight: 700;
    margin-right: 10px;
  }
}
.textstyle {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.auto-heigth {
  height: auto !important;
}
</style>
